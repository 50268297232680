export default {
    name: 'PrivacyPolicy',
    data () {
      return {
        document: null,
      }
    },
    methods: {
      getContent() {
        this.$root.$emit("loading");
        
        this.$prismic.client.getSingle('privacy_policy')
          .then((document) => {
            if (document) {
              this.document = document;
              this.$root.$emit("loaded");
            }
          });
      },
    },
    created() {
      this.getContent();
    }
  }